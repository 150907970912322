import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';

import { ApptypeName } from './apptype-name';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

@Injectable({
  providedIn: 'root'
})
export class ApptypeNameService {
  private url = environment.backend + "/api/apptypenames";

  constructor(private http: HttpClient) { }

  /** GET heroes from the server */
  getItems(): Observable<ApptypeName[]> {
    return this.http.get<ApptypeName[]>(this.url);
  }

  getItem(id: number): Observable<ApptypeName> {
    const url = `${this.url}/${id}`;
    return this.http.get<ApptypeName>(url);
  }

  getItemByName(name: string): Observable<ApptypeName> {
    const url = `${this.url}/${name}/itembyname`;
    return this.http.get<ApptypeName>(url);
  }

  getItemByIdApptype(idapptype: number): Observable<ApptypeName[]> {
    const url = `${this.url}/${idapptype}/itembyidapptype`;
    return this.http.get<ApptypeName[]>(url);
  }

  getItemsByIdUser(idUser: number, showInactive): Observable<ApptypeName[]> {
    const url = `${this.url}/${idUser}/${showInactive}/itemsbyiduser`;
    return this.http.get<ApptypeName[]>(url);
  }

  getItemsByIdOrganisation(idOrganisation: number, showInactive): Observable<ApptypeName[]> {
    const url = `${this.url}/${idOrganisation}/${showInactive}/itemsbyidorganisation`;
    return this.http.get<ApptypeName[]>(url);
  }

  getItemsByIdOrganisationAndRole(idOrganisation: number, role: string, showInactive): Observable<ApptypeName[]> {
    const url = `${this.url}/${idOrganisation}/${role}/${showInactive}/itemsbyidorganisation`;
    return this.http.get<ApptypeName[]>(url);
  }

  getItemsByIdUserAndRole(idUser: number, role: string, showInactive): Observable<ApptypeName[]> {
    const url = `${this.url}/${idUser}/${role}/${showInactive}/itemsbyiduserandrole`;
    return this.http.get<ApptypeName[]>(url);
  }

  getItemsByApptype(apptype: string): Observable<ApptypeName[]> {
    const url = `${this.url}/${apptype}/itemsbyapptype`;
    return this.http.get<ApptypeName[]>(url);
  }

  getItemsByApptypeName(apptypeName: string): Observable<ApptypeName[]> {
    const url = `${this.url}/${apptypeName}/itemsbyapptypename`;
    return this.http.get<ApptypeName[]>(url);
  }
  /** POST: add a new role to the server */
  addItem(item: ApptypeName): Observable<ApptypeName> {
    return this.http.post<ApptypeName>(this.url, item, httpOptions);
  }

  /** DELETE: delete the role from the server */
  deleteItem(item: ApptypeName | number): Observable<ApptypeName> {
    const id = typeof item === 'number' ? item : item.id;
    const url = `${this.url}/${id}`;

    return this.http.delete<ApptypeName>(url, httpOptions);
  }

  /** PUT: update the role on the server */
  updateItem(item: ApptypeName): Observable<any> {
    const url = `${this.url}/${item.id}`;
    return this.http.put<ApptypeName>(url, item, httpOptions);
  }

}