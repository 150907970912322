import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';

import { ApptypeNameField } from './apptype-name-field';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

@Injectable({
  providedIn: 'root'
})
export class ApptypeNameFieldService {
  private url = environment.backend + "/api/apptypenamesfield";

  constructor(private http: HttpClient) { }

  /** GET heroes from the server */
  getItems(): Observable<ApptypeNameField[]> {
    return this.http.get<ApptypeNameField[]>(this.url);
  }

  getCustomisableItemsByIdApptypeName(idApptypeName: number): Observable<ApptypeNameField[]> {
    const url = `${this.url}/${idApptypeName}/customisableitemsbyidapptypename`;
    return this.http.get<ApptypeNameField[]>(url);
  }

  getCustomisableItemsByApptypeNameName(apptypeNameName: string): Observable<ApptypeNameField[]> {
    const url = `${this.url}/${apptypeNameName}/customisableitemsbyidapptypenamename`;
    return this.http.get<ApptypeNameField[]>(url);
  }
  
  getItem(id: number): Observable<ApptypeNameField> {
    const url = `${this.url}/${id}`;
    return this.http.get<ApptypeNameField>(url);
  }

  /** POST: add a new role to the server */
  addItem(item: ApptypeNameField): Observable<ApptypeNameField> {
    return this.http.post<ApptypeNameField>(this.url, item, httpOptions);
  }

  /** DELETE: delete the role from the server */
  deleteItem(item: ApptypeNameField | number): Observable<ApptypeNameField> {
    const id = typeof item === 'number' ? item : item.id;
    const url = `${this.url}/${id}`;

    return this.http.delete<ApptypeNameField>(url, httpOptions);
  }

  /** PUT: update the role on the server */
  updateItem(item: ApptypeNameField): Observable<any> {
    const url = `${this.url}/${item.id}`;
    return this.http.put<ApptypeNameField>(url, item, httpOptions);
  }

}