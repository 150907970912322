import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';

import { ApptypeNameFieldValue } from './apptype-name-field-value';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

@Injectable({
  providedIn: 'root'
})
export class ApptypeNameFieldValueService {
  private url = environment.backend + "/api/apptypenamesfieldvalues";

  constructor(private http: HttpClient) { }

  /** GET heroes from the server */
  getItems(): Observable<ApptypeNameFieldValue[]> {
    return this.http.get<ApptypeNameFieldValue[]>(this.url);
  }

  getItem(id: number): Observable<ApptypeNameFieldValue> {
    const url = `${this.url}/${id}`;
    return this.http.get<ApptypeNameFieldValue>(url);
  }

  getItemsByObjectData(idApptypeNameField: number, name: string, value: string): Observable<ApptypeNameFieldValue[]> {
    const url = `${this.url}/${idApptypeNameField}/${name}/${value}/itemsbyobjectdata`;
    return this.http.get<ApptypeNameFieldValue[]>(url);
  }

  /** POST: add a new role to the server */
  addItem(item: ApptypeNameFieldValue): Observable<ApptypeNameFieldValue> {
    return this.http.post<ApptypeNameFieldValue>(this.url, item, httpOptions);
  }

  /** DELETE: delete the role from the server */
  deleteItem(item: ApptypeNameFieldValue | number): Observable<ApptypeNameFieldValue> {
    const id = typeof item === 'number' ? item : item.id;
    const url = `${this.url}/${id}`;

    return this.http.delete<ApptypeNameFieldValue>(url, httpOptions);
  }

  /** PUT: update the role on the server */
  updateItem(item: ApptypeNameFieldValue): Observable<any> {
    const url = `${this.url}/${item.id}`;
    return this.http.put<ApptypeNameFieldValue>(url, item, httpOptions);
  }

}