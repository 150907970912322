import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { TranslateService } from '@ngx-translate/core';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { ApptypeNameService } from 'src/app/shared/services/apptype-name/apptype-name.service';
import { UserTypeEnum } from 'src/app/shared/util/utilities';
import { ApptypeNameFieldService } from 'src/app/shared/services/apptype-name-field/apptype-name-field.service';
import { ApptypeNameFieldValueService } from 'src/app/shared/services/apptype-name-field-value/apptype-name-field-value.service';
import { ApptypeNameFieldValue } from 'src/app/shared/services/apptype-name-field-value/apptype-name-field-value';
import { switchMap } from "rxjs/operators";
import { Observable, of, forkJoin } from "rxjs";

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss']
})
export class MainComponent implements OnInit {
  private themeScriptElement: HTMLScriptElement;

  public fgSubscribe: FormGroup;
  public fgContactUs: FormGroup;

  public assistantHref: string = "";

  constructor(
    private fb: FormBuilder,
    public snackBar: MatSnackBar,
    private apptypeNameService: ApptypeNameService,
    private apptypeNameFieldService: ApptypeNameFieldService,
    private apptypeNameFieldValueService: ApptypeNameFieldValueService,
    public translate: TranslateService
  ) {
    this.themeScriptElement = document.createElement("script");
    this.themeScriptElement.src = "assets/js/main.js";
    document.body.appendChild(this.themeScriptElement);
  }

  ngOnInit(): void {
    this.assistantHref = environment.assistantApp;

    this.fgSubscribe = this.fb.group({
      subscribeFieldList: this.fb.array([]),
    });

    this.fgContactUs = this.fb.group({
      contactUsFieldList: this.fb.array([]),
    });

    this.getData();
  }

  public registerToNewsletter(): void {
    let configurableItemList: ConfigurableItem[]
      = this.fgSubscribe.value['subscribeFieldList'] as ConfigurableItem[];

    configurableItemList.forEach(configurableItem => {
      let instance: ApptypeNameFieldValue =
        new ApptypeNameFieldValue(configurableItem.typeName, configurableItem.valueValue, configurableItem.typeType, configurableItem.typeId);

      this.apptypeNameFieldValueService.getItemsByObjectData(instance.idApptypeNameField, instance.name, instance.value).pipe(
        switchMap(apptypeNameFieldValueItem =>
          apptypeNameFieldValueItem.length == 0 ? this.apptypeNameFieldValueService.addItem(instance) : of(null)
        )
      ).subscribe(retVal => {
        this.getData();

        if (retVal) {
          this.displaySnack("Thank you for registering to our newsletter!");
        } else {
          this.displaySnack("You already registered to our newsletter. Thank you. We we'll keep you updated!");
        }
      });
    });
  }

  public sendMessage(): void {
    let configurableItemList: ConfigurableItem[] =
      this.fgContactUs.value['contactUsFieldList'] as ConfigurableItem[];

    let executionArray: Observable<ApptypeNameFieldValue>[] = [];

    configurableItemList.forEach(configurableItem => {
      let instance: ApptypeNameFieldValue =
        new ApptypeNameFieldValue(configurableItem.typeName, configurableItem.valueValue, configurableItem.typeType, configurableItem.typeId);

      executionArray.push(this.apptypeNameFieldValueService.addItem(instance));
    });

    forkJoin(executionArray).subscribe(retVal => {
      this.getData();
      this.displaySnack("Thank you for contacting us, we'll get back to you soon!");
    });
  }

  get subscribeFieldList(): FormArray {
    return this.fgSubscribe.get('subscribeFieldList') as FormArray;
  }

  get contactUsFieldList(): FormArray {
    return this.fgContactUs.get('contactUsFieldList') as FormArray;
  }

  private getData() {
    while (this.subscribeFieldList.length !== 0) {
      this.subscribeFieldList.removeAt(0)
    }

    while (this.contactUsFieldList.length !== 0) {
      this.contactUsFieldList.removeAt(0)
    }

    forkJoin(
      this.apptypeNameFieldService.getCustomisableItemsByApptypeNameName(UserTypeEnum.UserType_Subscribe),
      this.apptypeNameFieldService.getCustomisableItemsByApptypeNameName(UserTypeEnum.UserType_ContactUs)
    ).subscribe(retValList => {
      retValList[0].forEach(apptypeNameFieldItem => {
        this.subscribeFieldList.push(
          this.fb.group(
            new ConfigurableItem(apptypeNameFieldItem.id, apptypeNameFieldItem.type, apptypeNameFieldItem.name, apptypeNameFieldItem.value)
          ));
      });

      retValList[1].forEach(apptypeNameFieldItem => {
        this.contactUsFieldList.push(
          this.fb.group(
            new ConfigurableItem(apptypeNameFieldItem.id, apptypeNameFieldItem.type, apptypeNameFieldItem.name, apptypeNameFieldItem.value)
          ));
      });
    });

  }

  private displaySnack(message: string) {
    this.snackBar.open(message, "Close", {
      duration: 3000,
      horizontalPosition: 'right'
    });
  }
}

export class ConfigurableItem {
  typeId = 0;       //from the ApptypeNamesFields
  typeName = '';    //from the ApptypeNamesFields
  typeValue = '';   //from the ApptypeNamesFields
  typeType = 0;     //from the ApptypeNamesFields

  valueId = 0;      //from the ApptypeNamesFieldValues
  valueName = '';   //from the ApptypeNamesFieldValues
  valueValue = "";  //from the ApptypeNamesFieldValues

  constructor(typeId: number, typeType: number, typeName: string, typeValue: string) {
    this.typeId = typeId;
    this.typeName = typeName;
    this.typeValue = typeValue;
    this.typeType = typeType;
  }
} 