export class ApptypeNameFieldValue {
    id: number;
    name: string;
    type: number;
    value: string;
    idApptypeNameField: number;

    constructor(name: string, value: string, type: number, idApptypeNameField: number) {
        this.name = name;
        this.type = type;
        this.value = value;
        this.idApptypeNameField = idApptypeNameField;
    }
}